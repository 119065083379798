import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Container,
  Heading,
  HStack,
  Link,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Card from "src/atoms/Card";
import Layout from "src/components/Layout";
import Markdown from "src/components/Markdown";
import Picture from "src/components/Picture";
import StructuredText from "src/components/StructuredText";
import { formatDate } from "src/lib/formatDate";

const Blog: FC<PageProps<Gatsby.BlogAuthorByIdQuery>> = ({
  data: { allDatoCmsBlogArticle: posts, datoCmsMember: author },
}) => {
  if (!posts || !author) return null;

  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: "description",
            content: `Découvrez les billets de blog de ${author.firstName} ${author.lastName}, membre de la Société Francophone de Philosophie de la Religion.`,
          },
        ]}
        title={`Blog de ${author.firstName} ${author.lastName}`}
      />

      <Container align="stretch" as={VStack} maxW="container.lg" spacing={8}>
        <Card size="lg">
          <HStack spacing={4}>
            <Picture
              borderRadius="full"
              image={author.portrait?.blogPageThumbnail}
            />

            <VStack align="flex-start">
              <Heading as="h1" size="xl">
                {`Blog de ${author.firstName} ${author.lastName}`}
              </Heading>

              <HStack
                divider={<StackDivider alignSelf="center" height="1em" />}
                spacing={3}
              >
                <Text fontSize="md" fontWeight="semibold" variant="caption">
                  {author.role}
                </Text>

                {author.website && (
                  <Text
                    as={Link}
                    fontSize="md"
                    fontWeight="semibold"
                    href={author.website}
                    variant="caption"
                  >
                    Voir le site internet
                    <ChevronRightIcon />
                  </Text>
                )}
              </HStack>
            </VStack>
          </HStack>
        </Card>

        {posts.nodes.map((blogPost) => (
          <Card key={blogPost.title} size="lg">
            <Text fontSize="sm" fontWeight="semibold" mb={2} variant="caption">
              {formatDate(blogPost.publicationDate!)}
            </Text>

            <Heading as="h2" mb={4} size="lg">
              {blogPost.title}
            </Heading>

            <Markdown>
              <StructuredText data={blogPost.body} />
            </Markdown>
          </Card>
        ))}
      </Container>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query BlogAuthorById($authorId: String) {
    datoCmsMember(originalId: { eq: $authorId }) {
      ...Member

      portrait {
        blogPageThumbnail: gatsbyImageData(width: 80, aspectRatio: 1)
      }
    }

    allDatoCmsBlogArticle(
      filter: { author: { originalId: { eq: $authorId } } }
    ) {
      nodes {
        ...BlogArticle
      }
    }
  }
`;
